import Actions from './Actions';
import Modules from "@/store/enums/Modules";
import store from "@/store";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Mutations } from './Mutations';
import CashState from '../../models/CashState';
import UserInfoCash from '@/models/cash/UserInfoCash';
import CashService from '@/core/services/cash/CashService';
import OpenCash from '@/models/cash/OpenCash';
import CashRegisterIncrease from '@/models/cash/CashRegisterIncrease';
import CashRegisterRetire from '@/models/cash/CashRegisterRetire';
import OpenedCashRegisterMovent from '@/models/cash/OpenedCashRegisterMovent';
import OptionSelect from '@/models/shared/OptionSelect';
import CashRegistersCombo from '@/models/cash/CashRegistersCombo';
import CashClose from '@/models/cash/CashClose';
import PaginationRequest from '@/models/general/PaginationRequest';
import FilterOpeningList from '@/models/cash/FilterOpeningList';
import FilterSalesCharges from '@/models/cash/FilterSalesCharges';
import RowOpeningList from '@/models/cash/RowOpeningList';
import { SaveValuesToStore } from '@/core/shared/AssingProps';
import TablePaginationRequest from '@/models/general/TablePaginationRequest';
import RowSalesCharges from '@/models/cash/RowSalesCharges';
import SalesChargesTotal from '@/models/cash/SalesChargesTotal';

@Module({dynamic: true, store, namespaced: true, name: Modules.CashModule})
export default class CashModule extends VuexModule implements CashState {
    idSelected = '';
    selectOpenedCash = [] as OpenedCashRegisterMovent[];
    selectCashRegisterCombo = [] as CashRegistersCombo[];
    initialValues = formDefaultValues
    loading = false;

    salesChargesTotal = {
        closureTotal: 0,
        chargesTotal: 0
    } as SalesChargesTotal;

    filters = {
        CashRegisterId: '',
        ClosureBegin: '',
        ClosureEnd: '',
        OpeningBegin: '',
        OpeningEnd: ''
    } as FilterOpeningList;
    paginationOpenList = {
        currentPage: 1
        , pageSize: 10 //default
    } as PaginationRequest;
    recordsOpenList = [] as RowOpeningList[];
    totalRecordsOpenList = 0;
    pdf = "";
    pdfRetire = "";


    filtersSalesCharges = {
        folio: '',
        guid: '',
        Name: ''
    } as FilterSalesCharges;
    paginationSalesCharges = {
        currentPage: 1
        , pageSize: 10 //default
    } as PaginationRequest;
    recordsSalesCharges = [] as RowSalesCharges[];
    totalRecordsSalesCharges = 0;

    get getbase64PDF(){
        return  "data:application/pdf;base64," + this.pdf;
    }

    get getbase64PDFRetire(){
        return  "data:application/pdf;base64," + this.pdfRetire;
    }

    get getUserInfoCash(): UserInfoCash {
        return this.initialValues;
    }

    get getCashRegisters(): CashRegistersCombo[] {
        return this.selectCashRegisterCombo;
    }

    get getMoments(): OpenedCashRegisterMovent[] {
        return this.selectOpenedCash;
    }

    get getMomentsCombo(): OptionSelect[] {
        return this.selectOpenedCash.map((val) => ({
            id: val.id,
            name:  val.cashierName + " - " + val.opening
        }));
    }

    get getRecordsRows(): RowOpeningList[] {
        return this.recordsOpenList.map((val) => ({
            id: val.id,
            cashRegisterId: val.cashRegisterId,
            cashierId: val.cashierId,
            initialBalance: val.initialBalance,
            cashRegisterIncrease: val.cashRegisterIncrease,
            cashRegisterRetire: val.cashRegisterRetire,
            closure: val.closure,
            pesoCashTotal: val.pesoCashTotal,
            dollarCashTotal: val.dollarCashTotal,
            debitCardTotal: val.debitCardTotal,
            creditCardTotal: val.creditCardTotal,
            payChecksTotal: val.payChecksTotal,
            transferTotal: val.transferTotal,
            returnNotesTotal: val.returnNotesTotal,
            bankDepositTotal: val.bankDepositTotal,
            supervisorId: val.supervisorId,
            openingDate: val.openingDate,
            closureDate: val.closureDate,
            cashierName: val.cashierName,
            cashRegisterName: val.cashRegisterName,
            closedDate: val.closedDate,
            opening: val.opening,
            approvedDate: val.approvedDate,
            diferenceClosureMovements: val.diferenceClosureMovements,
            totalMovements: val.totalMovements,
            textClosed: val.closureDate != null ? 'Abierto' : 'Cerrado',
            isClosed: (val.closureDate != null && val.approvedDate == ""),
            isApprobal: !(val.approvedDate != "")
        }));
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](data: UserInfoCash){
        this.loading = true;
    }

    @Mutation
    [Mutations.SET_DATA_CASH](data: UserInfoCash){
        this.initialValues = data;
    }

    
    @Mutation
    [Mutations.SET_VIEW_ADDITIONAL](data: string){
        this.pdf = data;
    }

    @Mutation
    [Mutations.SET_VIEW_RETIRE](data: string){
        this.pdfRetire = data;
    }

    @Mutation
    [Mutations.SET_CASHREGISTER_COMBO](data: CashRegistersCombo[]){
        this.selectCashRegisterCombo = data;
    }

    @Mutation
    [Mutations.SET_DATA_OPENED_CASH](data: OpenedCashRegisterMovent[]){
        this.selectOpenedCash = data;
    }

    @Mutation
    [Mutations.SET_PAGINATION](pagination: unknown){
        SaveValuesToStore(this.paginationOpenList, pagination);
    }

    @Mutation
    [Mutations.SET_PAGINATION_CHARGES](pagination: unknown){
        SaveValuesToStore(this.paginationSalesCharges, pagination);
    }

    @Mutation
    [Mutations.SET_FILTERS](filters: any){
        this.filters = filters;
        // SaveValuesToStore(this.filters, filters, true);
    }

    @Mutation
    [Mutations.SET_TOTAL_RECORDS](totalRecords: number){
        this.totalRecordsOpenList = totalRecords;
    }

    @Mutation
    [Mutations.SET_TABLE_DATA](totalRecords: RowOpeningList[]){
        this.recordsOpenList = totalRecords;
    }

    @Action
    async [Actions.CHANGE_PAGE](pagination: unknown){
          this.context.commit(Mutations.SET_PAGINATION, pagination);
          this.context.dispatch(Actions.GET_OPENING_PAGE);
    }
    
    @Action
    async [Actions.UPDATE_FILTERS](filters: unknown){
        //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
        this.context.commit(Mutations.SET_FILTERS, filters);
        this.context.commit(Mutations.SET_PAGINATION, {
          currentPage: 1 //si cambiamos filtros buscamos sobre la pagina uno
      });
        this.context.dispatch(Actions.GET_OPENING_PAGE);
    }

    @Mutation
    [Mutations.SET_FILTERS_CHARGES](filters: any){
        this.filtersSalesCharges = filters;
        // SaveValuesToStore(this.filters, filters, true);
    }
    @Mutation
    [Mutations.SET_TOTAL_RECORDS_CHARGES](totalRecords: number){
        this.totalRecordsSalesCharges = totalRecords;
    }

    @Mutation
    [Mutations.SET_TABLE_DATA_CHARGES](totalRecords: RowSalesCharges[]){
        this.recordsSalesCharges = totalRecords;
    }

    @Mutation
    [Mutations.SET_SALES_CHARGES_TOTAL](data: SalesChargesTotal){
        this.salesChargesTotal = data;
    }

    @Action
    async [Actions.CHANGE_PAGE_CHARGES](pagination: unknown){
          this.context.commit(Mutations.SET_PAGINATION_CHARGES, pagination);
          this.context.dispatch(Actions.GET_OPENING_PAGE);
    }
    
    @Action
    async [Actions.UPDATE_FILTERS_CHARGES](filters: unknown){
        //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
        this.context.commit(Mutations.SET_FILTERS_CHARGES, filters);
        this.context.commit(Mutations.SET_PAGINATION_CHARGES, {
          currentPage: 1 //si cambiamos filtros buscamos sobre la pagina uno
      });
      this.context.dispatch(Actions.GET_SALE_CHARGES);
    }

    @Action
    async [Actions.GET_OPENING_PAGE](){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
       
        const resp = (await service.getTableOpeningList(this.paginationOpenList, this.filters)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? {
                 data: [] as RowOpeningList[]
                , totalPages: 0
                , currentPage: this.paginationOpenList.currentPage
            }
        console.log(resp);
            this.context.commit(Mutations.SET_TABLE_DATA, resp.data);
            this.context.commit(Mutations.SET_PAGINATION,{ currentPage: resp.currentPage });
            this.context.commit(Mutations.SET_TOTAL_RECORDS, resp.totalRecords);
    }

    @Action
    async [Actions.GET_SALE_CHARGES](){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
       
        const resp = (await service.getTableSalesCharges(this.paginationSalesCharges, this.filtersSalesCharges)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? {
                 data: [] as RowSalesCharges[]
                , totalPages: 0
                , currentPage: this.paginationSalesCharges.currentPage
            }

            this.context.commit(Mutations.SET_TABLE_DATA_CHARGES, resp.data);
            this.context.commit(Mutations.SET_PAGINATION_CHARGES,{ currentPage: resp.currentPage });
            this.context.commit(Mutations.SET_TOTAL_RECORDS_CHARGES, resp.totalRecords);
    }

    @Action({ commit: Mutations.SET_CASHREGISTER_COMBO })
    async [Actions.GET_CASHREGISTER_COMBO](){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.getCashRegistersCombo()
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? []
    }

    @Action({ commit: Mutations.SET_SALES_CHARGES_TOTAL })
    async [Actions.GET_SALES_CHARGES_TOTAL](id: string){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.getGetSalesChargesTotal(id)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? []
    }

    @Action
    async [Actions.APPROVAL_CLOSED](id: string){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.putApprovalClosed(id)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? []
    }

    @Action({ commit: Mutations.SET_DATA_OPENED_CASH })
    async [Actions.GET_OPENED_CASH](){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.getOpenedCashRegisterMovent()
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? []
    }

    @Action({ commit: Mutations.SET_DATA_CASH })
    async [Actions.GET_INFO_CASH](){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.getInfoCash()
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? formDefaultValues
    }

    @Action
    async [Actions.OPEN_CASH](request: OpenCash){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.openCashRegister(request)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? false
    }

    @Action
    async [Actions.ADDITIONAL_FUND](request: CashRegisterIncrease){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.cashRegisterIncrease(request)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? false
    }

    @Action
    async [Actions.CASH_REGISTER_RETIRE](request: CashRegisterRetire){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.cashRegisterRetire(request)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? false
    }

    @Action
    async [Actions.CLOSE_CASH](request: CashClose){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.cashClose(request)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? false
    }

    @Action({ commit: Mutations.SET_VIEW_ADDITIONAL })
    async [Actions.GET_VIEW_ADDITIONAL](id: string){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.GetAdditionalFoundView(id)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? false
    }

    @Action({ commit: Mutations.SET_VIEW_RETIRE })
    async [Actions.GET_VIEW_RETIRE](id: string){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.GetRetireView(id)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? false
    }

}
const service =  new CashService();

/**
 * Valores por defecto del formulario
 */
 const formDefaultValues = {
    id: "",
    code: "",
    isActive: false,
    hasOpendCashRegister: false,
    cashRegister: "",
    cashRegisterId: ""
} as UserInfoCash;